import React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Container, Box } from "@material-ui/core";
import KaHtml from "./KaHtml";
import * as constants from "../../constants";
import useScreensize from "../../hooks/useScreensize";

const useStyles = makeStyles(theme => ({
  img: {
    backgroundSize: "cover"
  }
}));

const KaImageWithText = ({ content }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { xs, sm, md, lg, xl } = useScreensize();
  return (
    <Container maxWidth="lg">
      <Box display="flex" flexDirection={xs ? "column" : "row"}>
        <Box
          width="40%"
          flexShrink={0}
          className={classes.img}
          style={{
            backgroundImage:
              "url(" +
              constants.BACKEND_URL +
              "/storage/uploads" +
              content.image_text.image.path +
              ")"
          }}
        ></Box>
        <Box
          py={{ xs: 3, md: 8 }}
          px={{ xs: 3, md: 8 }}
          flexDirection="column"
          display="flex"
          flexGrow={1}
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          bgcolor={theme.palette.primary.main}
          color="white"
        >
          <KaHtml>{content.image_text.text}</KaHtml>
        </Box>
      </Box>
    </Container>
  );
};

export default KaImageWithText;
