import React from "react";
import {
  Typography,
  Button,
  Card,
  CardMedia,
  CardContent
} from "@material-ui/core";
import * as constants from "../constants";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  cardWrapper: {
    padding: 0,
    minHeight: 215,
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(2)
    }
  },
  cardWrapper_1_3: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 2),
      width: "50%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "400px"
    }
  },
  cardWrapper_2_3: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 2),
      width: "50%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "800px"
    }
  },
  cardWrapper_3_3: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 2),
      width: "50%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "1216px"
    }
  },
  card: {
    maxWidth: "100%",
    minHeight: "100%",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(3)
    }
  },
  details: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row"
    }
  },
  content: {
    flex: "1"
  },
  media: {
    width: "100%",
    height: 200,
    [theme.breakpoints.up("md")]: {
      width: 100,
      height: 100,
      borderRadius: 4,
      margin: theme.spacing(2)
    }
  },
  avatar: {
    backgroundColor: theme.palette.secondary.dark
  },
  news: {
    marginTop: 40,
    marginBottom: 40
  }
}));

const NewsItem = ({ news }) => {
  const classes = useStyles();

  const createMarkup = html => {
    return { __html: html };
  };

  const img = news.image.path && (
    <CardMedia
      className={classes.media}
      image={constants.BACKEND_URL + news.image.path}
    />
  );

  const readMoreBtn = news.link && (
    <Button variant="contained" color="primary">
      Mehr erfahren
    </Button>
  );

  return (
    <div
      className={clsx(
        classes.cardWrapper,
        news.width === "1/3" && classes.cardWrapper_1_3,
        news.width === "2/3" && classes.cardWrapper_2_3,
        news.width === "3/3" && classes.cardWrapper_3_3
      )}
    >
      <Card className={classes.card}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography component="h5" variant="h5">
              {news.title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="div">
              <div dangerouslySetInnerHTML={createMarkup(news.description)} />
            </Typography>
            {readMoreBtn}
          </CardContent>
          {img}
        </div>
      </Card>
    </div>
  );
};

export default NewsItem;
