import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as constants from "../constants";
import API from "../utils/API";
import { Box } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  keyvisual: {
    width: "100%",
    height: "auto",
    display: "block"
  }
}));

const Keyvisual = ({ identifier }) => {
  const [keyvisual, setKeyvisual] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (!identifier) {
      return;
    }
    async function fetchData() {
      try {
        const resultData = await API.post(`/collections/get/Keyvisual`, {
          filter: { identifier }
        });
        const data = resultData.data ? resultData.data : {};
        setKeyvisual(
          data.entries && data.entries.length ? data.entries[0] : null
        );
      } catch (e) {
        console.log("FEHLER: ", e);
      }
    }
    fetchData();
  }, [identifier]);

  const img = keyvisual && keyvisual.image && (
    <img
      className={classes.keyvisual}
      src={constants.BACKEND_URL + keyvisual.image.path}
      alt=""
    />
  );

  if (!img) {
    return null;
  }

  return (
    <Box zIndex={1} position="relative">
      {img}
    </Box>
  );
};

export default Keyvisual;
