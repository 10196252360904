import React, { useEffect, useState } from "react";
import API from "../../utils/API";
import { Container, Box, Typography, useTheme, Paper } from "@material-ui/core";
import useScreensize from "../../hooks/useScreensize";
import KaHtml from "../shared/KaHtml";
import Home from "./Home";
import KaSlider from "../shared/KaSlider";
import { makeStyles } from "@material-ui/styles";
import SplitScreenSlider from "../SplitScreenSlider";
import * as constants from "../../constants";
import KaImageWithText from "../shared/KaImageWithText";
import KaDownloads from "../shared/KaDownloads";
import KaMap from "../shared/KaMap";
import KaSeo from "../shared/KaSeo";
import Keyvisual from "../Keyvisual";
import Ausstellung from "../shared/Ausstellung";

const useStyles = makeStyles((theme) => ({
  splitScreenSlider: {
    background: "white",
    position: "relative",
    zIndex: 1,
    paddingTop: 0,
    paddingBottom: 0,
  },
  divider: {
    height: theme.spacing(10),
  },
}));

const Page = ({ match }) => {
  const [content, setContent] = useState(null);
  const classes = useStyles();
  const theme = useTheme();
  const { xs, sm, md, lg, xl } = useScreensize();

  useEffect(() => {
    if (!match.params.page) {
      return;
    }
    async function fetchData() {
      try {
        const resultData = await API.post(`/collections/get/Pages`, {
          filter: { identifier: match.params.page },
        });
        const data = resultData.data ? resultData.data : {};
        setContent(
          data.entries && data.entries.length ? data.entries[0] : null
        );
      } catch (e) {
        console.log("FEHLER: ", e);
      }
    }
    fetchData();
  }, [match.params]);

  if (!content) {
    return null;
  }

  const slider = Boolean(content.slider && content.slider.images.length) && (
    <KaSlider content={content} />
  );

  const splitScreenSlider = Boolean(content.split_screen_slider) && (
    <Paper className={classes.paper} elevation={10}>
      <SplitScreenSlider identifier={content.split_screen_slider.display} />
    </Paper>
  );

  const ausstellung = match.params.page === "kuechen" && <Ausstellung />;

  const imageWithText = Boolean(
    content.image_text && content.image_text.image && content.image_text.text
  ) && <KaImageWithText content={content} />;

  const downloads = Boolean(content.downloads) && (
    <KaDownloads content={content} />
  );

  const title = Boolean(content.title) && (
    <Container maxWidth="lg">
      <Box>
        <Typography
          variant="h3"
          color="textSecondary"
          component={content.slider ? "h3" : "h1"}
          align="center"
        >
          {content.title}
        </Typography>
      </Box>
    </Container>
  );

  const body = Boolean(content.body) && (
    <Container maxWidth="lg">
      <Box textAlign={content.body.length < 400 ? "center" : "left"}>
        <KaHtml>{content.body}</KaHtml>
      </Box>
    </Container>
  );

  const map = Boolean(content.map && content.map.lat && content.map.lng) && (
    <KaMap item={content.map} />
  );

  const seo = Boolean(content.seo) && (
    <KaSeo title={content.seo.title} description={content.seo.description} />
  );

  const elements = [
    slider || <></>,
    splitScreenSlider,
    ausstellung || <></>,
    title,
    body,
    imageWithText,
    map,
    downloads,
  ];

  return (
    <>
      {elements
        .filter((f) => Boolean(f))
        .map((item, index) => (
          <React.Fragment key={index}>
            {item}
            <div className={classes.divider} />
          </React.Fragment>
        ))}
      <Keyvisual identifier={content.identifier} />
      {seo}
    </>
  );
};

export default Page;
