import React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Container, Box, Paper, Typography } from "@material-ui/core";
import * as constants from "../../constants";
import useScreensize from "../../hooks/useScreensize";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  img: {
    margin: theme.spacing(1, 1, 1, 0),
    height: 120,
    width: "auto",
    borderRadius: 2,
    transition: "all 0.2s",
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    "&:hover": {
      transform: "scale(1.02)"
    },
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(3, 3, 3, 0),
      height: 150
    }
  }
}));

const KaDownloads = ({ content }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { xs, sm, md, lg, xl } = useScreensize();
  return (
    <>
      <Container maxWidth="lg">
        {Boolean(content.downloads_title) && (
          <Typography variant="h6" component="h4">
            {content.downloads_title}
          </Typography>
        )}
        <Box display="flex" flexWrap="wrap">
          {content.downloads.map((d, index) => (
            <a href={d.value.url} target="_blank" key={index}>
              <img
                className={classes.img}
                src={
                  constants.BACKEND_URL +
                  "/storage/uploads" +
                  d.value.image.path
                }
              />
            </a>
          ))}
        </Box>
      </Container>
    </>
  );
};

export default KaDownloads;
