import React from "react";
import { Typography, Button, makeStyles, Box } from "@material-ui/core";
import * as constants from "../constants";
import useScreensize from "../hooks/useScreensize";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  col1: {
    width: "100%",
    flexGrow: 1,
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.up("md")]: {
      width: "50%"
    },
    [theme.breakpoints.down("xs")]: {
      order: 2
    }
  },
  text: {
    flexGrow: 1
  },
  col2: {
    width: "100%",
    flexGrow: 1,
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      width: "50%"
    },
    [theme.breakpoints.down("xs")]: {
      order: 1,
      padding: 0,
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
      width: "auto"
    }
  },
  col2ContentWrapper: {
    boxSizing: "content-box",
    height: 0,
    width: "100%",
    paddingBottom: "80%",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      paddingBottom: "56%"
    }
  },
  col2Content: {
    padding: theme.spacing(4),
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    background: "#efefef",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    alignItems: "flex-start",
    justifyContent: "flex-end"
  },
  dark: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0,0,0,0.3)"
  },
  imageText: {
    zIndex: 1
  },
  subtitle: {
    color: "#fff",
    textShadow: "1px 1px 3px rgba(0,0,0,0.7)",
    textTransform: "uppercase"
  },
  subtitle2: {
    color: "rgba(255,255,255,0.7)"
  },
  moreBtn: {
    color: "#fff",
    marginLeft: theme.spacing(-1.5)
  }
}));

const TeaserItem = ({ item, imagePosition, teaserRef }) => {
  const classes = useStyles();
  const { xs, sm, md, lg, xl } = useScreensize();

  const createMarkup = html => {
    return { __html: html };
  };

  const textCol = (
    <div className={classes.col1}>
      <Typography variant="h3" color="textSecondary" className={classes.title}>
        {item.title}
      </Typography>
      <Typography variant="body1" component="div" className={classes.text}>
        <div dangerouslySetInnerHTML={createMarkup(item.text)} />
      </Typography>
      <Button
        component={Link}
        to={item.link}
        variant="contained"
        color="primary"
        className={classes.btn}
      >
        {item.button_title}
      </Button>
    </div>
  );

  const imageCol = (
    <div className={classes.col2}>
      <div className={classes.col2ContentWrapper}>
        <div
          className={classes.col2Content}
          style={{
            backgroundImage:
              "url(" + constants.BACKEND_URL + item.image.path + ")"
          }}
        >
          <div className={classes.dark} />
          <div className={classes.imageText}>
            <Typography variant="h4" className={classes.subtitle}>
              {item.subtitle}
            </Typography>
            <Typography variant="h6" className={classes.subtitle2}>
              {item.subtitle2}
            </Typography>
            <Button component={Link} to={item.link} className={classes.moreBtn}>
              Mehr
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  let cols = (
    <>
      {textCol}
      {imageCol}
    </>
  );

  if (imagePosition === "left") {
    cols = (
      <>
        {imageCol}
        {textCol}
      </>
    );
  }

  return (
    <Box
      ref={teaserRef || undefined}
      my={{ xs: 1, md: 4 }}
      display="flex"
      flexDirection={xs ? "column" : "row"}
    >
      {cols}
    </Box>
  );
};

export default TeaserItem;
