import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Box } from "@material-ui/core";
import API from "../../utils/API";
import * as constants from "../../constants";

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 99,
    position: "relative",
    backgroundColor: "white"
  },
  img: {
    margin: theme.spacing(1, 1, 1, 0),
    height: 100,
    width: "auto",
    borderRadius: 2,
    transition: "all 0.2s",
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(3, 3, 3, 0),
      height: 120
    },
    "&:hover": {
      transform: "scale(1.05)"
    }
  }
}));

const Partner = () => {
  const classes = useStyles();
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const resultData = await API.post(`/singletons/get/WebsiteSettings`);
        const data = resultData.data ? resultData.data : {};
        setSettings(data);
        console.log("SETTINGS", data);
      } catch (e) {
        console.log("FEHLER: ", e);
      }
    }
    fetchData();
  }, []);

  if (!settings) {
    return null;
  }

  return (
    <Box
      px={{ xs: 2, sm: 4, lg: 8 }}
      display="flex"
      minHeight={200}
      justifyContent="center"
      alignItems="center"
      className={classes.root}
      flexWrap="wrap"
    >
      {settings.partner.map((d, index) => (
        <a href={d.value.url} target="_blank" key={index}>
          <img
            className={classes.img}
            src={
              constants.BACKEND_URL + "/storage/uploads" + d.value.image.path
            }
          />
        </a>
      ))}
    </Box>
  );
};

export default Partner;
