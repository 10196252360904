import React, { useEffect, useState, useRef } from "react";
import SplitScreenSlider from "../SplitScreenSlider";
import News from "../News";
import Keyvisual from "../Keyvisual";
import Teaser from "../Teaser";
import red from "@material-ui/core/colors/red";
import ReactPlayer from "react-player";
import {
  Paper,
  Container,
  makeStyles,
  Box,
  useTheme,
  Typography,
} from "@material-ui/core";
import Img from "../../images/starter_unscharf.jpg";
import useScreensize from "../../hooks/useScreensize";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import { Link } from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";
import { grey } from "@material-ui/core/colors";
import Partner from "../shared/Partner";
import Ausstellung from "../shared/Ausstellung";
import KaSeo from "../shared/KaSeo";

const seoTitle = "Möbel Karch Kaiserslautern: Ihr Experte für Traumküchen";
const seoDescription = "Tauchen Sie ein in die Welt hochwertiger Küchen bei Möbel Karch in Kaiserslautern. Lassen Sie sich von unserer großen Auswahl inspirieren und genießen Sie individuelle Beratung für Ihre perfekte Kücheneinrichtung.";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      backgroundImage: "url(" + Img + ")",
      backgroundAttachment: "fixed",
      backgroundPosition: "center center",
      backgroundSize: "auto 120%",
    },
  },
  card: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  avatar: {
    backgroundColor: red[500],
  },
  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
  },
  paper: {
    position: "relative",
    zIndex: 999,
    overflow: "hidden",
  },
  container: {
    marginBottom: -100,
    zIndex: 9,
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
      marginBottom: 0,
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
  content: {
    background: "white",
    position: "relative",
    zIndex: 1,
    paddingTop: 1,
    paddingBottom: 50,
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
  },
  chevron: {
    width: 50,
    height: 50,
    opacity: 0.4,
  },
  chevron2: {
    width: 50,
    height: 50,
    marginTop: -40,
    opacity: 0.4,
  },
  bigButtonTitle: {
    textTransform: "uppercase",
    fontSize: "1rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.4rem",
    },
  },
  bigButton: {
    cursor: "pointer",
    position: "relative",
    top: theme.spacing(3),
    boxShadow: "0 0 4px rgba(0,0,0,0.5)",
    transition: "top 0.2s ease-in",
    "&:hover": {
      top: theme.spacing(1),
    },
  },
  mobileVideoWrapper: {
    width: "100%",
    height: 0,
    boxSizing: "content-box",
    paddingBottom: "56.25%",
    position: "relative",
  },
  mobileVideoFrame: {
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
  },
}));

const BigButton = ({ title, path, onClick }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Box
      onClick={onClick}
      className={classes.bigButton}
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor={theme.palette.primary.main}
      color="white"
      flexDirection="column"
      pt={4}
      pb={4}
      width={{ sm: 170, md: 210, lg: 250 }}
      flexShrink={0}
      mx={1}
      borderRadius={4}
    >
      <Typography variant="h5" className={classes.bigButtonTitle}>
        {title}
      </Typography>
      <ChevronDownIcon className={classes.chevron} />
      <ChevronDownIcon className={classes.chevron2} />
    </Box>
  );
};
let scrollInterval;
const scrollToRef = (ref) => {
  const scrollDuration = 500;
  const scrollDestination =
    ref.current.getBoundingClientRect().top + window.pageYOffset - 120;
  const scrollStep = scrollDestination / (scrollDuration / 15);
  setTimeout(() => {
    if (scrollInterval) {
      clearInterval(scrollInterval);
    }
  }, 750);
  scrollInterval = setInterval(function () {
    if (window.scrollY < scrollDestination) {
      window.scrollBy(0, scrollStep);
    } else {
      console.log("Ready");
      clearInterval(scrollInterval);
    }
  }, 15);
};

const Home = () => {
  const classes = useStyles();
  const { xs, sm, md, lg, xl } = useScreensize();
  const windowSize = useWindowSize();
  const [videoSize, setVideoSize] = useState(null);
  const [videoWrapperSize, setVideoWrapperSize] = useState(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  const refs = [ref1, ref2, ref3];

  useEffect(() => {
    if (!windowSize || windowSize[0] === 0) {
      return;
    }
    const isLandscape = windowSize[0] > windowSize[1];

    if (isLandscape) {
      const wrapperHeight = windowSize[1] - 64;
      const wrapperWidth = windowSize[0];

      let height = wrapperHeight;
      let width = height / 0.5625;
      if (width < windowSize[0]) {
        width = windowSize[0];
        height = width * 0.5625;
      }

      setVideoWrapperSize({ width: wrapperWidth, height: wrapperHeight });
      setVideoSize({ width, height });
      return;
    }

    const wrapperHeight = windowSize[1] / 2.5;
    const wrapperWidth = windowSize[0];

    let height = wrapperHeight;
    let width = height / 0.5625;
    if (width < windowSize[0]) {
      width = windowSize[0];
      height = width * 0.5625;
    }

    setVideoWrapperSize({ width: wrapperWidth, height: wrapperHeight });
    setVideoSize({ width, height });
  }, [windowSize]);

  if (!videoSize || !videoWrapperSize) {
    return null;
  }

  const videoWrapperStyle = {
    overflow: "hidden",
    left: 0,
    top: 0,
    zIndex: 1,
    width: "100%",
    height: videoWrapperSize.height,
    position: "relative",
    backgroundColor: grey[900],
  };

  const videoStyle = {
    position: "fixed",
    left: ((videoSize.width - videoWrapperSize.width) / 2) * -1,
    top:
      ((videoSize.height - videoWrapperSize.height) / 2) * -1 + (xs ? 56 : 64),
    width: videoSize.width,
    height: videoSize.height,
  };

  const mobileVideo = xs && (
    <div className={classes.mobileVideoWrapper}>
      <iframe
        className={classes.mobileVideoFrame}
        title="Möbel Karch Imagevideo"
        src="https://player.vimeo.com/video/362673875"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      ></iframe>
    </div>
  );

  const executeScroll = (ref) => scrollToRef(ref);

  return (
    <>
    <div className={classes.root}>
      {!xs && (
        <>
          <div style={videoWrapperStyle}>
            <div className={classes.reactPlayerWrapper} style={videoStyle}>
              <ReactPlayer
                url="https://vimeo.com/362673875/3676e34de2"
                playing
                loop
                volume={0}
                muted
                width="100%"
                height="100%"
                className={classes.reactPlayer}
              />
            </div>
          </div>
          <Box
            zIndex={1}
            position="fixed"
            bottom={windowSize[1] - videoWrapperSize.height - (xs ? 56 : 64)}
            left={0}
            right={0}
            display="flex"
            justifyContent="center"
          >
            <BigButton
              title="Küchen"
              path="#"
              onClick={() => {
                executeScroll(ref1);
              }}
            />
            <BigButton
              title="Schranksysteme"
              path="#"
              onClick={() => {
                executeScroll(ref2);
              }}
            />
            <BigButton
              title="Wohnen"
              path="#"
              onClick={() => {
                executeScroll(ref3);
              }}
            />
          </Box>
        </>
      )}
      <Box
        zIndex={1}
        position="relative"
        pb={2}
        pt={xs ? 5 : 0}
        px={xs ? 2 : 0}
        bgcolor={xs ? "transparent" : "white"}
      >
        <Paper>
          <SplitScreenSlider identifier="startseite" />
        </Paper>
      </Box>
      <div className={classes.content}>
        {mobileVideo}
        <News />
      </div>
      <Ausstellung />
      <div className={classes.content}>
        <Teaser refs={refs} />
      </div>
      <Partner />
      <Keyvisual identifier="startseite" />
    </div>
    <KaSeo title={seoTitle} description={seoDescription} />
    </>
  );
};

export default Home;
