import React, { useState } from "react";
import useScreensize from "../../hooks/useScreensize";
import {
  useTheme,
  Box,
  Typography,
  makeStyles,
  IconButton
} from "@material-ui/core";
import KaHtml from "./KaHtml";
import { Carousel } from "react-responsive-carousel";
import * as constants from "../../constants";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Lightbox from "react-image-lightbox";
import ArrowExpandAllIcon from "mdi-react/ArrowExpandAllIcon";

const useStyles = makeStyles(theme => ({
  sliderWrapper: {
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2)
    }
  },
  slide: {
    position: "relative",
    backgroundSize: "cover",
    backgroundPosition: "center top",
    backgroundRepeat: "no-repeat",
    overflow: "hidden",
    cursor: "pointer"
  },
  btnPrev: {
    position: "absolute",
    left: theme.spacing(0.5),
    top: "50%",
    marginTop: -15,
    color: "white",
    padding: 0
  },
  btnNext: {
    position: "absolute",
    right: theme.spacing(0.5),
    top: "50%",
    marginTop: -15,
    color: "white",
    padding: 0
  },
  btnSliderIcon: {
    width: 50,
    height: 50
  },
  zoomIcon: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: "white",
    pointerEvents: "none"
  }
}));

const KaSlider = ({ content }) => {
  const classes = useStyles();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const theme = useTheme();
  const { xs, sm, md, lg, xl, realSm } = useScreensize();
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const sliderHeight = xs ? (realSm ? 400 : 250) : 500;

  const onPrev = () => {
    setCurrentSlideIndex(state => state - 1);
  };

  const onNext = () => {
    setCurrentSlideIndex(state => state + 1);
  };

  const onUpdateSlider = event => {
    // console.log(event);
  };

  let calculatedSlideIndex = currentSlideIndex % content.slider.images.length;
  if (calculatedSlideIndex < 0) {
    calculatedSlideIndex = content.slider.images.length + calculatedSlideIndex;
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection={xs ? "column" : "row"}
        height={xs ? undefined : sliderHeight}
      >
        <Box
          overflow="hidden"
          width={xs ? "100%" : "66%"}
          flexShrink={0}
          bgcolor={theme.palette.secondary.dark}
        >
          <div className={classes.sliderWrapper}>
            <Carousel
              autoPlay={false}
              selectedItem={calculatedSlideIndex}
              onChange={onUpdateSlider}
              infiniteLoop
              showArrows={false}
              showThumbs={false}
              swipeable={true}
              showStatus={false}
            >
              {content.slider.images.map((item, index) => (
                <div
                  onClick={() => {
                    setIsOpen(true);
                    setPhotoIndex(index);
                  }}
                  key={index}
                  className={classes.slide}
                  style={{
                    backgroundImage:
                      "url(" + constants.BACKEND_URL + item.path + ")",
                    height: sliderHeight
                  }}
                >
                  {item.showSiegel && <div className={classes.siegel} />}
                </div>
              ))}
            </Carousel>
            <ArrowExpandAllIcon className={classes.zoomIcon} />
            {!xs && (
              <>
                <IconButton className={classes.btnPrev} onClick={onPrev}>
                  <ChevronLeftIcon className={classes.btnSliderIcon} />
                </IconButton>
                <IconButton className={classes.btnNext} onClick={onNext}>
                  <ChevronRightIcon className={classes.btnSliderIcon} />
                </IconButton>
              </>
            )}
          </div>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
          bgcolor={theme.palette.secondary.main}
          color="white"
          textAlign="center"
          py={3}
          px={{ xs: 2, sm: 8 }}
        >
          <Typography variant="h3" component="h1" gutterBottom>
            {content.slider.title}
          </Typography>
          <Typography variant="h6" component="h2" gutterBottom>
            {content.slider.subtitle}
          </Typography>
          <KaHtml>{content.slider.text}</KaHtml>
        </Box>
      </Box>
      {isOpen && (
        <Lightbox
          reactModalStyle={{ overlay: { zIndex: 9999999 } }}
          mainSrc={
            constants.BACKEND_URL + content.slider.images[photoIndex].path
          }
          nextSrc={
            constants.BACKEND_URL +
            content.slider.images[
              (photoIndex + 1) % content.slider.images.length
            ].path
          }
          prevSrc={
            constants.BACKEND_URL +
            content.slider.images[
              (photoIndex + content.slider.images.length - 1) %
                content.slider.images.length
            ].path
          }
          onCloseRequest={() => {
            setIsOpen(false);
          }}
          onMovePrevRequest={() => {
            setPhotoIndex(
              current =>
                (current + content.slider.images.length - 1) %
                content.slider.images.length
            );
          }}
          onMoveNextRequest={() => {
            setPhotoIndex(
              current => (current + 1) % content.slider.images.length
            );
          }}
        />
      )}
    </>
  );
};

export default KaSlider;
