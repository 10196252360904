import React, { useState, useEffect } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import Slide1 from "../../images/starter.jpg";
import KaVideoSlider from "./KaVideoSlider";
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import clsx from "clsx";
import API from "../../utils/API";
import Vimeo from "@u-wave/react-vimeo";
import useScreensize from "../../hooks/useScreensize";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 9,
    position: "relative",
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down("xs")]: {
      paddingBottom: theme.spacing(0),
    },
  },
  slideImg: {
    width: "100%",
    height: 0,
    boxSizing: "content-box",
    paddingBottom: "56.25%",
    position: "relative",
  },
  videoPlayer: {
    position: "absolute",
    left: 0,
    right: 0,
    width: "100%",
    height: "100%",
    "& > iframe": {
      width: "100%",
      height: "100%",
    },
  },
  arrow: {
    position: "absolute",
    left: theme.spacing(3),
    top: "50%",
    marginTop: -70,
    height: 60,
    width: 60,
    background: "rgba(0,0,0,0.5)",
    color: "white",
    borderRadius: 30,
    opacity: 0.8,
    transition: "all 0.3s ease-in",
    cursor: "pointer",
    "&:hover": {
      opacity: 1,
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  arrowRight: {
    left: "auto",
    right: theme.spacing(3),
  },
}));

const Ausstellung = () => {
  const classes = useStyles();
  const [slideIndex, setSlideIndex] = useState({ from: 0, to: 0 });
  const [videos, setVideos] = useState([]);
  const { xs, sm, md, lg, xl } = useScreensize();

  useEffect(() => {
    async function fetchData() {
      try {
        const resultData = await API.post(`/singletons/get/Kuechenausstellung`);
        const data = resultData.data ? resultData.data : {};
        if (data && data.Videos) {
          setVideos(data.Videos.map((m) => m.value.vimeo_url));
        }
        console.log(data);
      } catch (e) {
        console.log("FEHLER: ", e);
      }
    }
    fetchData();
  }, []);

  const next = () => {
    let { to } = slideIndex;
    const fromNew = to;
    if (to >= imageSlides.length - 1) {
      to = 0;
    } else {
      to += 1;
    }
    setSlideIndex({ from: fromNew, to });
  };

  const prev = () => {
    let { to } = slideIndex;
    const fromNew = to;
    if (to <= 0) {
      to = imageSlides.length - 1;
    } else {
      to -= 1;
    }
    setSlideIndex({ from: fromNew, to });
  };

  const imageSlides = videos.map((url, i) => (
    <div className={classes.slideImg}>
      <Vimeo video={url} className={classes.videoPlayer} />
    </div>
  ));

  return (
    <>
      <Box bgcolor="white" className={classes.root}>
        <Typography variant="h2" align="center" color="secondary">
          Highlights unserer Ausstellung
        </Typography>
        <Box width="100%" overflow="hidden" pb={{ xs: 3, sm: 3 }} mt={3}>
          <Box width="120%" ml={{ xs: -1, sm: -3 }}>
            {imageSlides.length && (
              <KaVideoSlider
                slideIndex={slideIndex}
                slides={imageSlides}
                slidesToShow={xs ? 2 : 3}
                dots={true}
                autoplay={true}
              />
            )}
          </Box>
          <ChevronLeftIcon
            className={clsx(classes.arrow, classes.arrowLeft)}
            onClick={prev}
          />
          <ChevronRightIcon
            className={clsx(classes.arrow, classes.arrowRight)}
            onClick={next}
          />
        </Box>
      </Box>
    </>
  );
};

export default Ausstellung;
