import * as React from "react";
import { LatLng } from "pigeon-maps";
import MarkerIcon from "mdi-react/MapMarkerIcon";
import { useTheme } from "@material-ui/core";

const PIN_CENTER = {
  x: 24,
  y: 24
};

// freely adapted from https://github.com/mariusandra/pigeon-marker/blob/master/src/index.js
export const KaMapMarker = React.memo(({ left, top }) => {
  const theme = useTheme();
  return (
    <MarkerIcon
      style={{
        color: theme.palette.primary.main,
        width: 48,
        height: 48,
        position: "absolute",
        left: left - PIN_CENTER.x,
        top: top - PIN_CENTER.y
      }}
    />
  );
});
