import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";
import blueGrey from "@material-ui/core/colors/blueGrey";

export const THEME = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        main: "#e20434"
      },
      secondary: {
        main: blueGrey[500]
      },
      background: {
        paper: "#f5f5f5",
        default: "#fff"
      }
    },
    typography: {
      fontFamily: "Roboto",
      h2: {
        fontFamily: "Roboto",
        fontWeight: 400
      }
    },
    status: {
      danger: "orange"
    }
  })
);
