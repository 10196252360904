import React, { useState, useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import blueGrey from "@material-ui/core/colors/blueGrey";
import pink from "@material-ui/core/colors/pink";
import clsx from "clsx";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import customStyles from "./SplitScreenSlider.module.css";
import Swipe from "react-easy-swipe";
import * as constants from "../constants";
import API from "../utils/API";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  block: {
    backgroundSize: "cover",
    backgroundPosition: "center right",
    position: "relative",
    overflow: "hidden"
  },
  block1: {
    height: 210,
    [theme.breakpoints.up("sm")]: {
      height: 400
    },
    [theme.breakpoints.up("md")]: {
      height: 600
    }
  },
  block2: {
    height: 350,
    backgroundColor: blueGrey[700],
    [theme.breakpoints.up("md")]: {
      height: 600
    }
  },
  layer: {
    position: "absolute",
    width: "110%",
    height: "110%",
    top: 0,
    background: "linear-gradient(to right, #e91e63, #f44336)"
  },
  layerNext: {
    left: "110%"
  },
  layerPrev: {
    left: "-110%"
  },
  layerNextActive: {
    left: "-110%",
    transition: "left 1s linear"
  },
  layerPrevActive: {
    left: "110%",
    transition: "left 1s linear"
  },
  content: {
    position: "absolute",
    left: "-100%",
    top: 0,
    width: "100%",
    height: "100%",
    overflow: "visible",
    display: "flex"
  },
  contentItem: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    flexShrink: 0,
    flexDirection: "column",
    zIndex: 0
  },
  actionBtn: {
    position: "absolute",
    top: "50%",
    zIndex: 99,
    color: "#fff",
    marginTop: "-15px",
    opacity: 1,
    transition: "opacity 0.5s"
  },
  nextBtn: {
    right: "5px",
    [theme.breakpoints.up("md")]: {
      right: "30px"
    }
  },
  prevBtn: {
    left: "5px",
    [theme.breakpoints.up("md")]: {
      left: "30px"
    }
  },
  hiddenBtn: {
    opacity: 0,
    pointerEvents: "none"
  },
  contentNextActive: {
    left: "-200% !important",
    transition: "left 1s ease-out"
  },
  contentPrevActive: {
    left: "0 !important",
    transition: "left 1s ease-out"
  },
  contentHeading: {
    color: "#fff",
    width: "70%",
    fontSize: 40,
    [theme.breakpoints.up("md")]: {
      fontSize: 50,
      width: "60%"
    }
  },
  contentBody: {
    color: blueGrey[100],
    marginTop: "10px",
    width: "70%",
    fontSize: 15,
    lineHeight: 1.3,
    [theme.breakpoints.up("md")]: {
      fontSize: 20,
      width: "60%",
      lineHeight: 1.4,
      marginTop: "20px"
    }
  },
  contentIcon: {
    background: blueGrey[700],
    marginTop: "-40px",
    marginBottom: "15px",
    [theme.breakpoints.up("md")]: {
      marginTop: "-80px",
      marginBottom: "50px"
    }
  },
  indicatorWrapper: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: "20px",
    height: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 9,
    [theme.breakpoints.up("md")]: {
      bottom: "100px"
    }
  },
  indicatorItem: {
    display: "block",
    width: "40px",
    height: "3px",
    margin: "5px",
    background: blueGrey[300],
    transition: "background 0.4s"
  },
  indicatorItemActive: {
    background: pink[500]
  },
  contentIconImage: {
    width: "100%",
    height: "100%"
  }
}));

const SplitScreenSlider = ({ identifier }) => {
  const [isPrevOn, setIsPrevOn] = useState(false);
  const [isNextOn, setIsNextOn] = useState(false);
  const [starterImage, setStarterImage] = useState(0);
  const [contentPosition, setContentPosition] = useState(0);
  const [currentContentItems, setCurrentContentItems] = useState([]);
  const [swipeHoriziontally, setSwipeHoriziontally] = useState(0);
  const [slides, setSlides] = useState([]);
  const classes = useStyles();
  const contentPositionRef = useRef(contentPosition);
  contentPositionRef.current = contentPosition;

  useEffect(() => {
    if (!identifier) {
      return;
    }
    async function fetchData() {
      try {
        const resultData = await API.post(`/collections/get/Slider`, {
          filter: { identifier }
        });
        const data = resultData.data ? resultData.data : {};
        setSlides(
          data.entries && data.entries.length ? data.entries[0].slide : []
        );
        setCurrentContentItems(
          data.entries && data.entries.length
            ? [
                data.entries[0].slide[0].value,
                data.entries[0].slide[0].value,
                data.entries[0].slide[0].value
              ]
            : []
        );
      } catch (e) {
        console.log("FEHLER: ", e);
      }
    }
    fetchData();
  }, [identifier]);

  const onSwipeEnd = event => {
    if (swipeHoriziontally === 0 || isNextOn || isPrevOn) {
      return;
    }
    if (swipeHoriziontally > 50) {
      handleClickPrev();
    }
    if (swipeHoriziontally < -50) {
      handleClickNext();
    }
    setSwipeHoriziontally(0);
  };

  const onSwipeStart = event => {
    setSwipeHoriziontally(0);
  };

  const onSwipeMove = (position, event) => {
    if (position.x) {
      setSwipeHoriziontally(position.x);
    }
  };

  const handleClickPrev = () => {
    const current = slides[contentPosition].value;
    const prev =
      contentPosition > 0
        ? slides[contentPosition - 1].value
        : slides[slides.length - 1].value;
    setContentPosition(state => (state > 0 ? state - 1 : slides.length - 1));
    setCurrentContentItems([prev, current, current]);
    setIsPrevOn(true);

    setTimeout(() => {
      setStarterImage(state => (state > 0 ? state - 1 : slides.length - 1));
    }, 500);

    setTimeout(() => {
      setIsPrevOn(false);
      setCurrentContentItems([
        slides[contentPositionRef.current].value,
        slides[contentPositionRef.current].value,
        slides[contentPositionRef.current].value
      ]);
    }, 1000);
  };

  const handleClickNext = () => {
    const current = slides[contentPosition].value;
    const next =
      contentPosition < slides.length - 1
        ? slides[contentPosition + 1].value
        : slides[0].value;
    setContentPosition(state => (state < slides.length - 1 ? state + 1 : 0));
    setCurrentContentItems([current, current, next]);
    setIsNextOn(true);

    setTimeout(() => {
      setStarterImage(state => (state < slides.length - 1 ? state + 1 : 0));
    }, 500);
    setTimeout(() => {
      setIsNextOn(false);
      setCurrentContentItems([
        slides[contentPositionRef.current].value,
        slides[contentPositionRef.current].value,
        slides[contentPositionRef.current].value
      ]);
    }, 1000);
  };

  console.log(currentContentItems);

  const renderedContentItems = currentContentItems
    .map((item, index) => (
      <div
        component={item.link ? Link : undefined}
        className={classes.contentItem}
        key={index}
        to={item.link || undefined}
      >
        <div className={clsx(customStyles.contentIcon, classes.contentIcon)}>
          <img
            src={constants.BACKEND_URL + item.icon.path}
            alt=""
            className={classes.contentIconImage}
          />
        </div>
        <Typography
          variant="h2"
          component="h3"
          align="center"
          className={classes.contentHeading}
        >
          {item.title}
        </Typography>
        <Typography
          variant="body1"
          align="center"
          component="p"
          className={classes.contentBody}
        >
          {item.description}
        </Typography>
      </div>
    ))
    .map((item, index) => {
      if (!currentContentItems[index].link) {
        return (
          <div className={classes.contentItem} key={index}>
            {item}
          </div>
        );
      }

      return (
        <Link
          className={classes.contentItem}
          key={index}
          to={currentContentItems[index].link}
        >
          {item}
        </Link>
      );
    });

  const indicatorItems = slides.map((item, index) => (
    <div
      className={clsx(
        classes.indicatorItem,
        starterImage === index && classes.indicatorItemActive
      )}
      key={index}
    />
  ));

  const image = slides[starterImage] && (
    <Box
      component="div"
      className={clsx(classes.block, classes.block1)}
      style={{
        backgroundImage:
          "url(" +
          constants.BACKEND_URL +
          slides[starterImage].value.bild.path +
          ")"
      }}
    >
      <div
        className={clsx(
          classes.layer,
          classes.layerNext,
          isNextOn && classes.layerNextActive
        )}
      />
      <div
        className={clsx(
          classes.layer,
          classes.layerPrev,
          isPrevOn && classes.layerPrevActive
        )}
      />
    </Box>
  );

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={6}>
            {image}
          </Grid>
          <Grid item xs={12} md={6}>
            <Swipe
              onSwipeStart={onSwipeStart}
              onSwipeMove={onSwipeMove}
              onSwipeEnd={onSwipeEnd}
            >
              <Box
                component="div"
                className={clsx(classes.block, classes.block2)}
              >
                <IconButton
                  size="medium"
                  onClick={handleClickPrev}
                  className={clsx(
                    classes.prevBtn,
                    classes.actionBtn,
                    (isNextOn || isPrevOn) && classes.hiddenBtn
                  )}
                >
                  <ChevronLeftIcon />
                </IconButton>
                <IconButton
                  size="medium"
                  onClick={handleClickNext}
                  className={clsx(
                    classes.nextBtn,
                    classes.actionBtn,
                    (isNextOn || isPrevOn) && classes.hiddenBtn
                  )}
                >
                  <ChevronRightIcon />
                </IconButton>
                <div
                  className={clsx(
                    classes.content,
                    isNextOn && classes.contentNextActive,
                    isPrevOn && classes.contentPrevActive
                  )}
                >
                  {renderedContentItems}
                </div>
                <div className={classes.indicatorWrapper}>{indicatorItems}</div>
              </Box>
            </Swipe>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default SplitScreenSlider;
