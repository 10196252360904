import React, { useState } from "react";
import { Typography, Button, makeStyles, Box } from "@material-ui/core";
import * as constants from "../constants";
import useScreensize from "../hooks/useScreensize";
import { Link } from "react-router-dom";
import KeyvisBeratung from "../images/Karch_Beratertag_keyvis_neu.jpg";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(theme => ({
  modal: {
    zIndex: 99999,
    position: "fixed",
    backgroundColor: "rgba(0,0,0,0.9)",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    display: "flex"
  },

  modalInner: {
    width: "100%",
    maxWidth: "940px",
    paddingTop: 60,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    overflow: "auto",
    margin: "0 auto"
  },

  keyvis: {
    width: "100%",
    height: "auto"
  },

  closeButton: {
    position: "absolute",
    right: 10,
    top: 10,
    color: "#fff"
  }
}));

const ModalBeratungstermin = () => {

  const [showModal, setShowModal] = useState(true);

  const classes = useStyles();

  const handleClose = (event) => {
    setShowModal(false);
  };

  return (
    <>
    {showModal && (

    
      <div className={classes.modal} onClick={handleClose}>
        <div className={classes.modalInner}>
          <img className={classes.keyvis} src={KeyvisBeratung} alt="Bitte vereinbaren Sie einen Beratungstermin" />
        </div>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
      </div>
      )}
    </>
  )
};

export default ModalBeratungstermin;