import React from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
  MenuList,
  MenuItem
} from "@material-ui/core";
import MuiLink from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/styles";
import PhoneIcon from "@material-ui/icons/Phone";
import clsx from "clsx";
import { Link } from "react-router-dom";
import KaHtml from "./shared/KaHtml";
import useScreensize from "../hooks/useScreensize";

const useStyles = makeStyles(theme => ({
  footer: {
    padding: theme.spacing(4, 0),
    marginTop: "auto",
    backgroundColor: theme.palette.primary.main,
    position: "relative",
    zIndex: 2,
    color: "white"
  },
  footerLinks: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      alignItems: "flex-end",
      justifyContent: "center"
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2)
    }
  },
  footerLink: {
    margin: theme.spacing(1, 0),
    color: "white",
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(2)
    }
  },
  phoneIcon: {
    marginRight: theme.spacing(1),
    width: 24,
    height: 24
  },
  title: {
    opacity: 0.7,
    marginBottom: theme.spacing(2)
  },
  menuItem: {
    height: 30,
    minHeight: 30,
    fontSize: "1rem"
  }
}));

const Footer = ({ settings }) => {
  const classes = useStyles();

  const footerMenu = Boolean(settings) && (
    <MenuList dense>
      {settings.main_menu.map((item, index) => (
        <MenuItem
          className={clsx(classes.menuItem)}
          button
          key={index}
          component={Link}
          to={item.value.url}
        >
          {item.value.title}
        </MenuItem>
      ))}
    </MenuList>
  );

  const footerSecondaryMenu = Boolean(settings) && (
    <MenuList dense>
      {settings.secondary_menu.map((item, index) => (
        <MenuItem
          className={clsx(classes.menuItem)}
          button
          key={index}
          component={Link}
          to={item.value.url}
        >
          {item.value.title}
        </MenuItem>
      ))}
    </MenuList>
  );

  const openingHours = Boolean(settings) && (
    <KaHtml>{settings.opening_hours}</KaHtml>
  );

  return (
    <>
      <footer className={classes.footer}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} sm={6} lg={3}>
              <Box>
                <Typography variant="h6" className={classes.title}>
                  So erreichen Sie uns:
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  width="100%"
                  justifyContent="flex-start"
                  mb={1}
                >
                  <PhoneIcon className={classes.phoneIcon} />
                  <Typography variant="h6" component="span">
                    0631-67690
                  </Typography>
                </Box>
                <Typography variant="body1">
                  <strong>Möbel Karch GmbH</strong>
                </Typography>
                <Typography variant="body1">
                  Kanalstraße 31-39 + 24-26
                </Typography>
                <Typography variant="body1" gutterBottom>
                  67655 Kaiserslautern
                </Typography>
                <Typography variant="body1">
                  <strong>Kundenparkplätze</strong> finden Sie in der
                  Kanalstraße 24 und 26
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Box mt={{ xs: 4, sm: 0 }}>
                <Typography variant="h6" className={classes.title}>
                  Unsere Öffnungszeiten:
                </Typography>
                {openingHours}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Box mt={{ xs: 4, lg: 0 }}>
                <Typography variant="h6" className={classes.title}>
                  Unser Angebot:
                </Typography>
                <Box ml={-2} mt={-1.5} pr={3}>
                  {footerMenu}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Box mt={{ xs: 4, lg: 0 }}>
                <Typography variant="h6" className={classes.title}>
                  Weitere Seiten:
                </Typography>
                <Box ml={-2} mt={-1.5} pr={3}>
                  {footerSecondaryMenu}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
