import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  slide: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0.5),
    },
  },
}));

const defaultSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  slidesToScroll: 1,
  swipeToSlide: true,
  speed: 500,
  easing: "ease-in",
  autoplaySpeed: 5000,
};

const KaVideoSlider = ({
  slideIndex,
  slides = [],
  slidesToShow = null,
  autoPlay = false,
  speed = 500,
  dots = true,
}) => {
  const classes = useStyles();
  const [slider, setSlider] = useState(null);
  const [sliderSettings, setSliderSettings] = useState(null);

  useEffect(() => {
    if (!slidesToShow) {
      return;
    }
    const s = Object.assign({}, defaultSettings);
    s.slidesToShow = slidesToShow;
    s.autoplay = autoPlay;
    s.speed = speed;
    s.dots = dots;
    console.log(slidesToShow);
    setSliderSettings(s);
  }, [slidesToShow, autoPlay, speed, dots]);

  useEffect(() => {
    if (!slider || !slideIndex) {
      return;
    }

    if (slideIndex.from === slideIndex.to - 1) {
      slider.slickNext();
      return;
    }

    if (slideIndex.from === slides.length - 1 && slideIndex.to === 0) {
      slider.slickNext();
      return;
    }

    if (slideIndex.from === slideIndex.to + 1) {
      slider.slickPrev();
      return;
    }

    if (slideIndex.from === 0 && slideIndex.to === slides.length - 1) {
      slider.slickPrev();
      return;
    }

    slider.slickGoTo(slideIndex.to);
  }, [slideIndex, slider]);

  if (!sliderSettings) {
    return null;
  }

  return (
    <>
      <Slider {...sliderSettings} ref={(s) => setSlider(s)}>
        {slides.map((item, i) => (
          <div className={classes.slide} key={i}>
            {item}
          </div>
        ))}
      </Slider>
    </>
  );
};

export default KaVideoSlider;
