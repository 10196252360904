import React from "react";
import Map from "pigeon-maps";
import { KaMapMarker } from "./KaMapMarker";
import { Box } from "@material-ui/core";
import KaHtml from "./KaHtml";
import { useTheme } from "@material-ui/styles";
import useScreensize from "../../hooks/useScreensize";

const providers = {
  osm: (x, y, z) => {
    const s = String.fromCharCode(97 + ((x + y + z) % 3));
    return `https://${s}.tile.openstreetmap.org/${z}/${x}/${y}.png`;
  },
  wikimedia: (x, y, z, dpr) => {
    return `https://maps.wikimedia.org/osm-intl/${z}/${x}/${y}${
      dpr >= 2 ? "@2x" : ""
    }.png`;
  },
  stamen: (x, y, z, dpr) => {
    return `https://stamen-tiles.a.ssl.fastly.net/terrain/${z}/${x}/${y}${
      dpr >= 2 ? "@2x" : ""
    }.jpg`;
  }
};

const KaMap = ({ item }) => {
  const theme = useTheme();
  const { xs, sm, md, lg, xl } = useScreensize();
  const position = [item.lat * 1, item.lng * 1];
  console.log("MAP");
  return (
    <Box
      display="flex"
      flexDirection={xs ? "column" : "row"}
      height={400}
      overflow="hidden"
    >
      <Box width={xs ? "100%" : "66%"} flexShrink={0}>
        <Map
          center={position}
          zoom={17}
          height={400}
          metaWheelZoom={true}
          provider={providers["osm"]}
        >
          <KaMapMarker
            anchor={position}
            payload={1}
            onClick={({ event, anchor, payload }) => {}}
          />
        </Map>
      </Box>
      <Box
        flexGrow={1}
        display="flex"
        alignItems="center"
        bgcolor={theme.palette.secondary.main}
        color="white"
        p={{ xs: 2, md: 8 }}
      >
        <KaHtml>{item.text}</KaHtml>
      </Box>
    </Box>
  );
};

export default KaMap;
