import React from "react";
import parse from "html-react-parser";
import { Typography } from "@material-ui/core";

const defaultReplaceMapping = [
  { tag: "p", variant: "body1", component: "p", className: "" },
  { tag: "h1", variant: "h3", component: "h1", className: "" },
  { tag: "h2", variant: "h4", component: "h2", className: "" },
  { tag: "h3", variant: "h5", component: "h3", className: "" },
  { tag: "h4", variant: "h6", component: "h4", className: "" }
];

let replaceCount = 0;

const replace = (domNode, replaceMapping) => {
  replaceCount++;
  if (domNode.type && domNode.type === "tag") {
    const mapping = replaceMapping.filter(f => f.tag === domNode.name)[0];
    if (mapping) {
      // console.log("MAP", mapping);
      return (
        <Typography
          gutterBottom
          key={replaceCount}
          variant={mapping.variant}
          component={mapping.component}
        >
          {domNode.children.map(child => replace(child, replaceMapping))}
        </Typography>
      );
    } else if (domNode.name === "br") {
      return <br key={replaceCount} />;
    } else {
      return parse(
        `<${domNode.name} key="${replaceCount}">${domNode.children.map(child =>
          replace(child, replaceMapping)
        )}</${domNode.name}>`
      );
    }
  }
  if (domNode.type && domNode.type === "text") {
    return domNode.data;
  }
};

const KaHtml = ({ children, replaceMapping = defaultReplaceMapping }) => {
  return (
    <>
      {parse(children, {
        replace: domNode => replace(domNode, replaceMapping)
      })}
    </>
  );
};

export default KaHtml;
