import React, { useState, useEffect } from "react";
import { Container, Typography, makeStyles } from "@material-ui/core";
import * as constants from "../constants";
import TeaserItem from "./TeaserItem";
import API from "../utils/API";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  title: {
    margin: theme.spacing(0, 0, 6, 0),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(4)
    }
  }
}));

const Teaser = ({ refs }) => {
  const [teaser, setTeaser] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    async function fetchData() {
      try {
        const resultData = await API.get(`/collections/get/Teaser`);
        const data = resultData.data ? resultData.data : {};
        setTeaser(data.entries && data.entries.length ? data.entries : []);
      } catch (e) {
        console.log("FEHLER: ", e);
      }
    }
    fetchData();
  }, []);

  return (
    <Container maxWidth="lg">
      <Typography
        variant="h2"
        align="center"
        color="secondary"
        className={classes.title}
      >
        Und wie möchten Sie leben?
      </Typography>
      {teaser.map((t, index) => (
        <TeaserItem
          teaserRef={refs && refs[index] ? refs[index] : undefined}
          item={t}
          key={index}
          imagePosition={index % 2 === 0 ? "left" : "right"}
        />
      ))}
    </Container>
  );
};

export default Teaser;
