import React, { useState, useEffect } from "react";
import { Container, Typography } from "@material-ui/core";
import Masonry from "react-masonry-component";
import NewsItem from "./NewsItem";
import * as constants from "../constants";
import { makeStyles } from "@material-ui/styles";
import API from "../utils/API";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(20)
    }
  },
  title: {
    margin: theme.spacing(6, 0, 6, 0)
  }
}));

const News = () => {
  const [entries, setEntries] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    async function fetchData() {
      try {
        const resultData = await API.get(`/collections/get/News`);
        const data = resultData.data ? resultData.data : {};
        setEntries(data.entries ? data.entries : []);
      } catch (e) {
        console.log("FEHLER: ", e);
      }
    }
    fetchData();
  }, []);

  const masonryOptions = {
    transitionDuration: 0,
    columnWidth: 1
  };
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          align="center"
          color="secondary"
          className={classes.title}
        >
          Aktuelles
        </Typography>
        <Masonry options={masonryOptions}>
          {entries.map(item => (
            <NewsItem key={item._id} news={item} />
          ))}
        </Masonry>
      </Container>
    </div>
  );
};

export default News;
